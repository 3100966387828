// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.st0 {
  fill: #60375f;
}
.st1 {
  fill: #1d1d1b;
}
.st2 {
  fill: #ea5e55;
}
.st3 {
  fill: #ee7b66;
}
.st4 {
  fill: #c84152;
}
.st5 {
  fill: #ac3a59;
}
.st6 {
  fill: #8c3358;
}
`, "",{"version":3,"sources":["webpack://./src/assets/ishare.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf","sourcesContent":[".st0 {\n  fill: #60375f;\n}\n.st1 {\n  fill: #1d1d1b;\n}\n.st2 {\n  fill: #ea5e55;\n}\n.st3 {\n  fill: #ee7b66;\n}\n.st4 {\n  fill: #c84152;\n}\n.st5 {\n  fill: #ac3a59;\n}\n.st6 {\n  fill: #8c3358;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
