import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes, useNavigate, useParams, NavigateFunction } from "react-router-dom";
import { Home } from "./pages/home/home";
import getBaseUrl from "util/getBaseUrl";
import styles from "./pages/home/home.module.css";

const Clearing = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [clearingInstance, setClearingInstance] = useState(null)
  const [offerPolicies, setOfferPolicies] = useState([])

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token") || "";

  // get clearing instances
  useEffect(() => {
    const headers = {
      'Authorization': `Bearer ${token}`
    }
    fetch(`${getBaseUrl()}/api/data/${params.target}/clearing-instances`, { headers })
      .then(res => res.json())
      .then(clearingInstances => {
        if (clearingInstances.length > 0) {
          // remove all rejected
          const unfiltered = clearingInstances.filter(x => !(x["status"] == "rejected" || x["status"] == "withdrawn"))
          return unfiltered[0];
        }
        return null;
      })
      .then(clearingInstance => {
        if (clearingInstance != null) {
          return fetch(`${getBaseUrl()}/api/clearing-instance/${clearingInstance["id"]}`, { headers })
            .then(res => res.json())
            .then(data => data["latest_eval"])
            .then(latestEval => {
              clearingInstance["latest_eval"] = latestEval
              return clearingInstance
            })
        }
        return clearingInstance;
      })
      .then(setClearingInstance)
  }, [params.target, token, setClearingInstance])

  // get offer policies
  useEffect(() => {
    const headers = {
      'Authorization': `Bearer ${token}`
    }
    fetch(`${getBaseUrl()}/api/data/${params.target}/offer-policies`, { headers })
      .then(res => res.json())
      .then(setOfferPolicies)
  }, [params.target, token, setOfferPolicies])

  const startClearing = useCallback((offerPolicyId: string) => {
    console.log(offerPolicyId)
    const headers = {
      'Authorization': `Bearer ${token}`,
    }
    fetch(`${getBaseUrl()}/api/offer-policies/${offerPolicyId}/clearing/start`,
          { headers, method: "POST" })
      .then(res => res.json())
      .then(() => {
        navigate(0)
      })
  }, []);

  const accessData = useCallback(() => {
    navigate(`/data/${params.target}?token=${token}`)
  }, [params.target, token])

  console.log(clearingInstance)
  let facts: any[] = []
  if (clearingInstance != null && clearingInstance["latest_eval"] != null) {
    const latestEval = clearingInstance["latest_eval"]
    facts = latestEval["evaluated_facts"]
  }

  return (
    <section className={styles.containerRegular}>
      <div>
        <h1>Clearing</h1>
        <p>Dataset: {params.target}</p>
        {clearingInstance != null ? (
          <div>
            <h3>Clearing Requesta</h3>
            <p>You have an outstanding Clearing Request.</p>
            <div>
              <h4>{clearingInstance["offer_policy"]["label"]["en-US"]}</h4>
              <p>Id: {clearingInstance["id"]}</p>
              <p>Status: {clearingInstance["status"]}</p>
              {clearingInstance["latest_eval"] != null && (
                <div>
                  <p>Required Facts: </p>
                  {facts.map(fact => {
                    return (<p>- {fact["fact_id"]}: {fact["evaluation"]}</p>)
                  })}
                </div>
              )}
              {clearingInstance["status"] === "approved" && <button onClick={accessData}>Access</button>}
            </div>
          </div>
        ) : (
          <div>
            <h3>Offer Policies</h3>
            <p>You don't have access yet to: {params.target}</p>
            <p>Get access via one of the following offers:</p>
            {offerPolicies.map(offerPolicy => {
              console.log(offerPolicy)
              return (<div>
                        <h4>{offerPolicy["label"]["en-US"]}</h4>
                        <button onClick={() => startClearing(offerPolicy["id"])}>Start Clearing</button>
                      </div>)
            })}
          </div>
        )}
      </div>
    </section>
  )
}

const DataView = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token") || "";
  const [content, setContent] = useState("")

  const navigate = useNavigate();
  const params = useParams();

  if (token == "") {
    navigate(`/login?target=${params.target}`);
  }

  useEffect(() => {
    const headers = {
      'Authorization': `Bearer ${token}`
    }
    fetch(`${getBaseUrl()}/api/data/${params.target}`, { headers })
      .then(res => {
        if (res.status == 401 && token != "") {
          navigate(`/clearing/${params.target}?token=${token}`)
        }
        return res;
      })
      .then(res => res.text())
      .then(setContent)
      .catch(err => console.error(err))
    
  }, [params.target, token])


  return (
    <section className={styles.containerRegular}>
      {content == "" ? (
        <div>
          <h1>Dataset: {params.target}</h1>
            <div>
              <p>Checking access...</p>
            </div>
        </div>
    ) : (<div>
          <h1>Dataset: {params.target}</h1>
          Access GRANTED!
          <h2>Content</h2>
          <textarea disabled={true} cols={80} rows={50}>{content}</textarea>
        </div>)}
    </section>
  )
}

const App = () => {
  //const [token, setToken] = useState("");

  return (  
    <Routes>
      <Route path="/data/:target" element={<DataView />}></Route>
      <Route path="/clearing/:target" element={<Clearing />}></Route>
      <Route path="/login" element={<Home />}></Route>
    </Routes>
  );
};

export default App;
