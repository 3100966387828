// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: hsla(344, 50%, 45%, 1);

  background: linear-gradient(
    90deg,
    hsla(344, 50%, 45%, 0.15) 0%,
    hsla(300, 80%, 10%, 0.15) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(344, 50%, 45%, 0.15) 0%,
    hsla(300, 80%, 10%, 0.15) 100%
  );
}

/* linear-gradient(to right, hsla(344, 50%, 45.13%, 0.15) 0.42%, hsla(300, 79.72%, 9.99%, 0.46)) */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,kCAAkC;;EAElC;;;;GAIC;;EAQD;;;;GAIC;AACH;;AAEA,kGAAkG","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\nbody {\n  background: hsla(344, 50%, 45%, 1);\n\n  background: linear-gradient(\n    90deg,\n    hsla(344, 50%, 45%, 0.15) 0%,\n    hsla(300, 80%, 10%, 0.15) 100%\n  );\n\n  background: -moz-linear-gradient(\n    90deg,\n    hsla(344, 50%, 45%, 0.15) 0%,\n    hsla(300, 80%, 10%, 0.15) 100%\n  );\n\n  background: -webkit-linear-gradient(\n    90deg,\n    hsla(344, 50%, 45%, 0.15) 0%,\n    hsla(300, 80%, 10%, 0.15) 100%\n  );\n}\n\n/* linear-gradient(to right, hsla(344, 50%, 45.13%, 0.15) 0.42%, hsla(300, 79.72%, 9.99%, 0.46)) */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
