// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_ishareButton__wCJ9B {
  border: 1px solid #61385f;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #61385f;
  cursor: pointer;
  padding: 12px;
  font-size: 18px;
  -webkit-user-select: none;
          user-select: none;
  font-family: lato;
  font-weight: 700;
  text-decoration: none !important;
}

.home_ishareButton__wCJ9B:active {
  background-color: #61385f16;
}

.home_container__BgDW7 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}

.home_containerRegular__NlScx {
  display: flex;
  height: 100%;
  min-height: 100vh;
}

.home_signinCard__jn0jM {
  background-color: white;
  width: 240px;
  height: 200px;
  padding: 32px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  font-family: lato;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home_signinCardHeader__FYM1D {
  margin: 0;
}

.home_signinCardDescription__DJqma {
  color: #1e293b;
}

.home_ishareLink__sT15H {
  text-decoration: none;
}

.home_tokenPre__G5cIM {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/home.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,cAAc;EACd,eAAe;EACf,aAAa;EACb,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,0CAA0C;EAC1C,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".ishareButton {\n  border: 1px solid #61385f;\n  background-color: white;\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  color: #61385f;\n  cursor: pointer;\n  padding: 12px;\n  font-size: 18px;\n  user-select: none;\n  font-family: lato;\n  font-weight: 700;\n  text-decoration: none !important;\n}\n\n.ishareButton:active {\n  background-color: #61385f16;\n}\n\n.container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  min-height: 100vh;\n}\n\n.containerRegular {\n  display: flex;\n  height: 100%;\n  min-height: 100vh;\n}\n\n.signinCard {\n  background-color: white;\n  width: 240px;\n  height: 200px;\n  padding: 32px;\n  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;\n  font-family: lato;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.signinCardHeader {\n  margin: 0;\n}\n\n.signinCardDescription {\n  color: #1e293b;\n}\n\n.ishareLink {\n  text-decoration: none;\n}\n\n.tokenPre {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ishareButton": `home_ishareButton__wCJ9B`,
	"container": `home_container__BgDW7`,
	"containerRegular": `home_containerRegular__NlScx`,
	"signinCard": `home_signinCard__jn0jM`,
	"signinCardHeader": `home_signinCardHeader__FYM1D`,
	"signinCardDescription": `home_signinCardDescription__DJqma`,
	"ishareLink": `home_ishareLink__sT15H`,
	"tokenPre": `home_tokenPre__G5cIM`
};
export default ___CSS_LOADER_EXPORT___;
